import React from "react";

export const Footer = () => {
  return (
    <div className="footer-container ">
      <div className="row">
        <div className="col-12 col-md-3 text-start pt-4">
          <div className="f-content-head">The Greek Gods</div>
          <div className="f-content-desc pt-3">
            800 unique NFts consisting of the 5 main Olympians, The Greek Gods
            is the official NFT collection by Adonis Knight
          </div>
        </div>
        <div className="col-12 col-md-3 offset-md-6 text-end  pt-4">
          <div className="social-link">
            <a
              href="https://twitter.com/adonisknightart?s=21"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/twitter.png"}
                alt="twitter"
                className="mx-2"
              />
            </a>
            <a
              href="https://medium.com/@adonisknightart"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/medium.png"}
                alt="twitter"
                className="mx-2"
              />
            </a>
          </div>
          <div className="row text-start pt-3">
            <div className="col-6">
              <ul className="nostyle">
                <li>About</li>
                <li>FAQs</li>
                <li>Contact Us</li>
              </ul>
            </div>
            <div className="col-6">
              <ul className="nostyle">
                <li>Roadmap</li>
                <li>Gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-12 text-center f-content-desc">
          All Rights Reserved © Adonis Knight 2021
        </div>
      </div>
    </div>
  );
};
