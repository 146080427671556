import { url } from "inspector";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Footer } from "../footer/footer";
import { Header } from "../header/header";
import Slider from "../slider/slider";
import * as anchor from "@project-serum/anchor";
import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "../../candy-machine";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(true); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const wallet = useAnchorWallet();
  // const val: any = wallet.publicKey !== undefined ? wallet.publicKey : "0";
  // console.log("WALLET", val);
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );
      console.log("CANDY", candyMachine);
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );
        const status: any = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div
        className="main-div"
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/images/top-banner.jpeg)",
          height: "600px",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="banner-text-section">
            <div className="text-center">
              <h1 className="banner-heading text-center">The Greek Gods</h1>
              <div className="tag-line">
                The stealth collection By Adonis Knight
              </div>
              <div>
                <button
                  className="god-btn primary"
                  disabled={isSoldOut || isMinting || !isActive}
                  onClick={onMint}
                  style={{ maxHeight: "60px", fontSize: "24px" }}
                >
                  {isSoldOut
                    ? `800/800 Minted`
                    : isActive
                    ? isMinting
                      ? "Minting"
                      : `${itemsRedeemed}/${itemsAvailable} Mint`
                    : "Minting Not Started"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="about"
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/images/left-circle.png)",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row mx-0">
            <div className="col-12 col-md-6 img-god-wh text-center">
              <img src={process.env.PUBLIC_URL + "/images/god-white.jpg"} />
            </div>
            <div className="col-12 col-md-6 p-1 pt-5 align-self-center">
              <div className="question-heading">
                What are the Greek Gods NFTs?
              </div>
              <div className="answer pt-3">
                <p>
                  I am a 16 year old 3D NFT artist from New Jersey. My grandma,
                  who was born in Greece, was insistent in filling my brain with
                  all the tales of her culture during my adolescent years. Ever
                  since then, Greek Mythilogy has continued to fascinate beyond
                  my wildest dreams. The stories it tells, written throughout
                  history, in the forms of the Greek Gods we all know today -
                  are marvelous. Art is a form of story telling that is
                  subjective to the holder. This first collection I have created
                  is only the stepping stone into a 3D universe of Ancient Greek
                  History. My first drop, consisting of Zeus, Posideon, Hades,
                  Aphrodite, and Apollo will forever serve as a ticket to future
                  drops of a greater 50 piece God and mythological creature
                  collection.
                </p>
                <p>
                  Join me on the quest to unlock the mysteries of Ancient Greek
                  History through 3D imaging and story telling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="gallary"
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/images/bg-1.png)",
          height: "600px",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <Slider />
        </div>
      </div>
      <div
        className="content-center"
        id="roadmap"
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/images/bg-2.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 text-center">
              <div className="section-heading text-center p-3">The Roadmap</div>
              <div className="section-content p-1 ">
                <p>
                  This art is my passion. I aim to get better and improve the
                  quality of art as this project progresses into being what I
                  have dreamt it to be.{" "}
                </p>
                <p>
                  Only holders of this original 800 piece collection will
                  benefit greatly from future collection drops related to The
                  Greek Gods. There will be 5 more drops held throughout the
                  course of 2022 to add to a greater 50 piece collection of
                  Greek Gods, Demi Gods, and Mythological Creatures. Those who
                  own this original collection will be the ONLY ones able to
                  mint future drops - making this original collection the focal
                  piece of my work. The price for these future drops will be a
                  fraction of the price this first collection will be, consider
                  it a dividend for holding onto the original collection. If you
                  own a piece from each collection at year end 2022, you will
                  receive an extremely rare piece exclusive to those who stuck
                  with the project through it all.
                </p>
                <p>
                  My goal is to bridge the gap between Solana projects and real
                  artists. I want Solana to be a home for artists like me to
                  showcase their skills and show uniqueness, something different
                  then your typical 2D PFP project. You could compare what I am
                  doing to the “GHXSTS” project on ETH.
                </p>
                <p>
                  I am so excited for what the future holds for this project.
                  Thank you to the community for extending all the love and
                  support, you guys are what makes this all possible!
                </p>
                <p>
                  Feel free to message me on Twitter to discuss more about my
                  passions, goals, and plans regarding art and my life.
                </p>
                <p>
                  Thank you, <br />
                  Adonis Knight
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/images/right-circle.png)",
          height: "700px",
          backgroundSize: "cover",
        }}
        id="faq"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 pb-5">
              <div className="py-5 section-heading text-center">
                Frequently Asked Questions
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What is the Mint Price?</Accordion.Header>
                  <Accordion.Body>
                    It will be 0.99 Sol. Although you must have atleast 0.001
                    Sol for covering up the gas fees.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    What is the collection size?
                  </Accordion.Header>
                  <Accordion.Body>
                    It is 800 pieces consistingof the 5 main Olympians.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>When is the mint date?</Accordion.Header>
                  <Accordion.Body>
                  The public sale will start on Jan 19th at 23:00 UTC.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Where can I learn more about this project or you?
                  </Accordion.Header>
                  <Accordion.Body>
                    Please read the medium article that is hyphenated on home
                    page. You can message me directly on my twitter
                    @adonisknight.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What is the purpose of this collection?
                  </Accordion.Header>
                  <Accordion.Body>
                    Consider it as a mint pass. The first collection, which will
                    forever hold a special spot in my heart, earns you the right
                    to mint future collections at reduced price. Only those who
                    hold the first collection hold the key to entire 50 piece
                    Greek God Collection.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    How big will the entire collection be?
                  </Accordion.Header>
                  <Accordion.Body>
                    The collection will consist of 5 drops, spread over a year
                    or more. Each drop will consist of 5-12 3D images that are
                    significant to Greek Mythology. The collection will consist
                    of Gods, Demi-Gods, and Mythological creatures.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Where can I buy/sell them?
                  </Accordion.Header>
                  <Accordion.Body>
                    These will be featured on Magic Eden post mint. This will be
                    only platform I will have these listen on.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
