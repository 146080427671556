import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
// import logo from '/images/logo.png'
import { Container, Nav, Navbar } from "react-bootstrap";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
// import { connectwallet } from '../../service/web3';
require("@solana/wallet-adapter-react-ui/styles.css");

export const Header: React.FC<any> = ({ page }: { page: string }) => {
  const [myAccount, setmyAccount] = useState("");
  const wallet = useAnchorWallet();
  useEffect(() => {
    const account = localStorage.getItem("account");
    if (account) {
      // connectwallet();
    }
  }, []);

  return (
    <Navbar
      expand="lg"
      sticky="top"
      variant="dark"
      style={{ background: "#000" }}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="god" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-7 ml-auto main-nav">
            {/* <Router> */}
            <Nav.Link className="nav-links" href="#about">
              ABOUT
            </Nav.Link>
            <Nav.Link className="nav-links" href="#gallary">
              GALLERY
            </Nav.Link>
            <Nav.Link className="nav-links" href="#roadmap">
              ROADMAP
            </Nav.Link>
            <Nav.Link className="nav-links" href="#faq">
              FAQS
            </Nav.Link>

            {/* </Router> */}
          </Nav>
          <Nav className="secondary-nav">
            <Nav.Link
              href="https://twitter.com/adonisknightart?s=21"
              target="_blank"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/twitter.png"}
                alt="twitter"
              />
            </Nav.Link>
            <Nav.Link
              href="https://medium.com/@adonisknightart"
              target="_blank"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/medium.png"}
                alt="twitter"
              />
            </Nav.Link>
            {/* <Nav.Link>
             {!wallet && <WalletMultiButton  className="god-btn primary"/>}
            </Nav.Link> */}
            <Nav.Link>
              <WalletMultiButton />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
