import React, { Component } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

export default class MySlider extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 col-md-8 offset-md-2 slider-container">
                <div className="pb-5 section-heading text-center">
                            THE GALLERY
                        </div>
                <Slider {...settings} className="my-slider">
                    <div> <img className="slider-image" src={process.env.PUBLIC_URL + '/images/slider-1.gif'} /></div>
                    <div> <img className="slider-image" src={process.env.PUBLIC_URL + '/images/slider-2.gif'} /></div>
                    <div> <img className="slider-image" src={process.env.PUBLIC_URL + '/images/slider-3.gif'} /></div>
                    <div> <img className="slider-image" src={process.env.PUBLIC_URL + '/images/slider-4.gif'} /></div>
                    <div> <img className="slider-image" src={process.env.PUBLIC_URL + '/images/slider-5.gif'} /></div>
                </Slider>
                </div>
            </div>
        )
    }
}
